import Vue from 'vue'
import VueRouter from 'vue-router'
// import CurriculumDetails from '../views/CurriculumDetails/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index/Index.vue'),
  },
  // // css动画
  // {
  //   path: '/Index',
  //   name: 'Index',
  //   component: () => import('../views/Index/Index.vue'),
  // },
  // 首页
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home/Home.vue'),
  },

  // 视频
  {
    path: '/videoPage',
    name: 'videoPage',
    component: () => import('../views/videoPage/videoPage.vue'),
  },
  // 关于我们
  {
    path: '/Aboutus',
    name: 'Aboutus',
    component: () => import('../views/Aboutus/Aboutus.vue'),
  },
  // 专题
  {
    path: '/subject',
    name: 'subject',
    component: () => import('../views/subject/subject.vue'),
  },
  // 专题详情
  {
    path: '/subjectdetail',
    name: 'subjectdetail',
    component: () => import('../views/subject/subjectdetail.vue'),
  },
  // 新闻
  {
    path: '/Newsweek',
    name: 'Newsweek',
    component: () => import('../views/Newsweek/Newsweek.vue'),
  },
  // 新闻详情
  {
    path: '/Newsweekdetail',
    name: 'Newsweekdetail',
    component: () => import('../views/Newsweek/Newsweekdetail.vue'),
  },
  // 活动
  {
    path: '/Activity',
    name: 'Activity',
    component: () => import('../views/Activity/Activity.vue'),
  },
  // 活动详情
  {
    path: '/Activitydetail',
    name: 'Activitydetail',
    component: () => import('../views/Activity/Activitydetail.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
